<div
    *ngIf="!isLink"
    class="azw-feedbackModal__icon"
    [class.--show]="userData && (OnBottom || alwaysVisible)"
    (click)="toggleModal(successSend)"
>
    <span class="azw-feedbackModal__badge" *ngIf="isPending"></span>
    <nx-icon name="product-help-question"></nx-icon>
</div>

<span *ngIf="isLink" class="azw-feedbackModal__link" (click)="toggleModal(successSend)"> Assistenza </span>

<ng-template #feedbackModal>
    <div class="azw-feedbackModal" nxModalContent>
        <div nxLayout="grid">
            <h3 class="azw-feedbackModal__title --modalForm" nxHeadline="subsection-large">Segnalazione - Helpdesk</h3>
            <form [formGroup]="feedbackForm" class="azw-feedbackModal__form" *ngIf="!successSend">
                <div nxRow class="azw-feedbackModal__input --input">
                    <div nxCol="12">
                        <nx-formfield label="Nome utente">
                            <input nxInput formControlName="name" />
                        </nx-formfield>
                    </div>
                </div>
                <div nxRow class="azw-feedbackModal__input --input">
                    <div nxCol="12,6">
                        <nx-formfield label="Mail a cui vuoi essere contattato *">
                            <input nxInput formControlName="email" />
                        </nx-formfield>
                    </div>
                    <div nxCol="12,6">
                        <nx-formfield label="Altri recapiti (telefono, altra mail, ...)">
                            <input nxInput formControlName="alternative_contact" />
                        </nx-formfield>
                    </div>
                </div>
                <div nxRow class="azw-feedbackModal__input --select">
                    <div nxCol="12">
                        <nx-formfield label="Ambito in cui si sta operando *">
                            <nx-dropdown [panelGrow]="true" formControlName="user_feedback_type_id">
                                <nx-dropdown-item *ngFor="let feedbackType of filteredUserFeedbackTypes" [value]="feedbackType.id">
                                    {{ 'CONSTANTS.' + constantsFE.USER_FEEDBACK_DIGITAL_TYPES + '.' + feedbackType.tag | translate }}
                                </nx-dropdown-item>
                            </nx-dropdown>
                            <nx-error nxFormfieldError> L'ambito è obbligatorio. </nx-error>
                        </nx-formfield>
                    </div>
                </div>
                <div nxRow class="azw-feedbackModal__input --input">
                    <div nxCol="12">
                        <nx-formfield label="Descrizione della segnalazione *">
                            <textarea
                                nxInput
                                type="text"
                                formControlName="description"
                                name="description"
                                rows="8"
                                #textArea
                                required
                            ></textarea>
                            <nx-error nxFormfieldError> La descrizione è obbligatoria. </nx-error>
                        </nx-formfield>
                    </div>
                </div>
                <div nxRow class="azw-feedbackModal__input --input">
                    <div nxCol="12">
                        <nx-formfield label="Evento interessato">
                            <input nxInput name="event" formControlName="event" />
                        </nx-formfield>
                    </div>
                </div>
                <div nxRow class="azw-feedbackModal__input --radio">
                    <div nxCol="12">
                        <nx-radio-group name="hasDocument" formControlName="hasDocument" tabindex="0">
                            <nx-label size="small">Vuoi allegare qualche documento o screenshot (xls, jpg, png, pdf)? </nx-label>
                            <nx-radio formGroupName="attachment" value="si" class="azw-feedbackModal__inputAttachment"
                                >Si
                                <nx-link
                                    *ngIf="!feedbackForm.get('attachment.fileStream')?.value"
                                    (click)="feedbackForm.get('hasDocument')?.value === 'si'"
                                    [class.--disabled]="feedbackForm.get('hasDocument')?.value !== 'si'"
                                >
                                    <label for="attachment">Carica file</label>
                                </nx-link>
                                <span *ngIf="feedbackForm.get('attachment.fileStream')?.value">
                                    <i>{{ feedbackForm.get('attachment.name')?.value }}</i>
                                    <nx-link (click)="removeFile(feedbackForm.get('attachment.id')?.value)">Elimina </nx-link>
                                </span>
                                <input
                                    id="attachment"
                                    type="file"
                                    ngFileSelect
                                    [options]="options"
                                    formControlName="document"
                                    (uploadOutput)="onUploadOutput($event)"
                                    [uploadInput]="uploadInput"
                                />
                            </nx-radio>
                            <nx-radio
                                value="no"
                                (click)="
                                    feedbackForm.get('attachment.fileStream')?.value
                                        ? removeFile(feedbackForm.get('attachment.id')?.value)
                                        : null
                                "
                            >
                                No
                            </nx-radio>
                        </nx-radio-group>
                        <nx-error nxFormfieldError *ngIf="fileTooLarge"> Il file è troppo grande. </nx-error>
                    </div>
                </div>
            </form>
            <div nxRow *ngIf="successSend">
                <div nxCol="12">
                    <div class="azw-feedbackModal__success">
                        <p>Feedback inviato con successo!</p>
                        <p *ngIf="code">
                            ID segnalazione: <b>{{ code }}</b>
                        </p>
                        <nx-icon name="check-circle"></nx-icon>
                    </div>
                </div>
            </div>
            <div class="azw-feedbackModal__actions">
                <button nxModalClose *ngIf="!successSend" type="button" nxButton="secondary">Annulla</button>
                <button
                    *ngIf="!successSend"
                    type="button"
                    [disabled]="feedbackForm.invalid || loadingApi"
                    nxButton="primary"
                    (click)="sendFeedback()"
                >
                    Invia
                </button>
                <button nxModalClose *ngIf="successSend" type="button" nxButton="primary">Chiudi</button>
            </div>
        </div>
    </div>
</ng-template>
